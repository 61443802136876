import React from "react"
import styled from "styled-components"

const Offer = styled.div`
  :not(&:last-of-type) {
    margin-bottom: 50px;
  }
`
const List = styled.ul``
const ListItem = styled.li`
 font-weight: 600;
 letter-spacing: 0.5px;

  :not(&:last-of-type) {
    margin-bottom: 1em;
  }
`

const Description = styled.div`
  margin-bottom: 0.5em;
`;

const BASE_URL = 'https://oficyna-profilaktycznapl.shoparena.pl/skins/user/rwd_shoper_1/images/user/';

export class Conferences extends React.Component {

  imageUrls = {
    Zakopane: `${BASE_URL}/Zakopane.png`,
    Gdansk: `${BASE_URL}/konferencja_gdansk.jpg`,
    Szklarska: `${BASE_URL}/Szklarska.png`,
    Sandomierz: `${BASE_URL}/sandomierz.jpg`,
    Mielno: `${BASE_URL}/konferencja_mielno.jpeg`,
    Jurata: `${BASE_URL}/Jurata.png`,
    Krakow: `${BASE_URL}/krakow.png`,
    Kolobrzeg: `${BASE_URL}/konferencja_kolobrzeg.jpg`,
    Poronin: `${BASE_URL}/poronin.jpg`,
    Kazimierz: `${BASE_URL}/konferencja_kazimierz_dolny.jpg`,
    Poznan: `${BASE_URL}/poznan.jpg`,
    Ustka: `${BASE_URL}/ustka.jpg`,
    Jastarnia: `${BASE_URL}/jastarnia.jpg`,
    Wroclaw: `${BASE_URL}/konferencja_wroclaw.jpg`,
    Karpacz: `${BASE_URL}/karpacz.jpg`,
    Mragowo: `${BASE_URL}/mragowo.jpg`
  }

  render() {
    return (
      <div>
        <br />

        <h4>TERMINARZ NA ROK 2024</h4>

        <Offer>
          <List>
            <ListItem>
              <Description>GDAŃSK</Description>

              <div>
                <img src={this.imageUrls.Gdansk}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>ZAKOPANE</Description>

              <div>
                <img src={this.imageUrls.Zakopane}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>SZKLARSKA PORĘBA</Description>

              <div>
                <img src={this.imageUrls.Szklarska}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>SANDOMIERZ</Description>

              <div>
                <img src={this.imageUrls.Sandomierz}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>MIELNO</Description>

              <div>
                <img src={this.imageUrls.Mielno}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>JURATA</Description>

              <div>
                <img src={this.imageUrls.Jurata}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>KRAKÓW</Description>

              <div>
                <img src={this.imageUrls.Krakow}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>KOŁOBRZEG</Description>

              <div>
                <img src={this.imageUrls.Kolobrzeg}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>PORONIN</Description>

              <div>
                <img src={this.imageUrls.Poronin}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>KAZIMIERZ DOLNY</Description>

              <div>
                <img src={this.imageUrls.Kazimierz}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>POZNAŃ</Description>

              <div>
                <img src={this.imageUrls.Poznan}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>GDAŃSK</Description>

              <div>
                <img src={this.imageUrls.Gdansk}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>PORONIN</Description>

              <div>
                <img src={this.imageUrls.Poronin}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>USTKA</Description>

              <div>
                <img src={this.imageUrls.Ustka}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>SZKLARSKA PORĘBA</Description>

              <div>
                <img src={this.imageUrls.Szklarska}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>JASTARNIA</Description>

              <div>
                <img src={this.imageUrls.Jastarnia}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>ZAKOPANE</Description>

              <div>
                <img src={this.imageUrls.Zakopane}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>MRĄGOWO</Description>

              <div>
                <img src={this.imageUrls.Mragowo}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>KARPACZ</Description>

              <div>
                <img src={this.imageUrls.Karpacz}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>WROCŁAW</Description>

              <div>
                <img src={this.imageUrls.Wroclaw}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>

            <ListItem>
              <Description>SANDOMIERZ</Description>

              <div>
                <img src={this.imageUrls.Sandomierz}
                     alt=""
                     width="275"
                     height="183" />
              </div>
            </ListItem>
          </List>
        </Offer>
      </div>
    )
  }
}
