import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import { Conferences } from "../components/conferences/conferences"

const Paragraph = styled.p`
  text-align: justify;
  margin-block-start: 2em;
`

const Container = styled.div`
  margin-top: 6em;

  @media (min-width: 400px) {
    margin: 2em;
  }
  
  @media (min-width: 700px) {
    margin: 4em;
    margin-top: 8em;
  }
`

class SzkoleniaWyjazdowePage extends React.Component {
  render() {
    return (
      <Layout
        location={this.props.location.pathname}
      >
        <Container>
          <h1>SZKOLENIA WYJAZDOWE 2024</h1>
          <br />

          <h3>Na szkolenia zapraszamy przedstawicieli:</h3>

          <Paragraph>Urzędów Miast i Gmin, Prezydentów/Burmistrzów/Wójtów, przewodniczących KRPA, Pełnomocników ds.
            Profilaktyki i RPA, członków i kandydatów do KRPA. osób wydających zezwolenia na sprzedaż alkoholu, osób,
            które należą do interdyscyplinarnych zespołów ds. przeciwdziałania przemocy w rodzinie, kierowników OPS,
            pracowników socjalnych, asystentów rodziny, policjantów, strażników miejskich, przedstawicieli służby
            zdrowia, kuratorów, pracowników PCPR, przedstawicieli oświaty: dyrektorów, pedagogów/psychologów
            szkolnych/przedszkolnych, pracowników świetlic oraz innych specjalistów zainteresowanych tematyką
            szkolenia.</Paragraph>

          <Paragraph>
            <strong>Zachęcamy do skorzystania z dofinansowania z Krajowego Funduszu Szkoleniowego</strong>
          </Paragraph>

          <Paragraph>
            SZCZEGÓŁOWE INFORMACJE, HARMONOGRAMY, TERMINY I KARTY ZGŁOSZENIOWE DOSTĘPNE U KOORDYNATORA POD NR. TEL&nbsp;
             <a href="tel:124225470">12 422 54 70</a> / <a href="tel:577776886">577 776 886</a>
          </Paragraph>

          <Conferences></Conferences>

        </Container>
      </Layout>
    )
  }
}

export default SzkoleniaWyjazdowePage
